<template>
  <div id="page-error">
    <b-container>
      <b-row>
        <b-col
          lg="8"
          offset-lg="2"
        >
          <h4 class="secondary">C'è stato un problema</h4>
          <br>
          <p v-html="error"></p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { Base64 } from 'js-base64'
export default {
  name: 'not-found',
  metaInfo() {
    return {
      title: 'not found',
      meta: [
        { vmid: 'metaPrerenderStatusCode', name: 'prerender-status-code', content: '404' },
        { vmid: 'robots', name: 'robots', content: 'noindex' },
      ]
    }
  },
  data() {
    return {
      error: (this.$route.query.error) ? Base64.decode(this.$route.query.error) : 'La pagina richiesta non è stata trovata'
    }
  }
}
</script>

<style lang="scss">
#page-error {
  padding-top: $grid-gutter-width;
  margin-bottom: 6rem;
  @include media-breakpoint-up(lg) {
    margin-top: 72px;
    margin-bottom: 8rem;
  }
}
</style>